<template>
  <div class="markets-wrapper js-loaded-indices">
    <a
      v-for="(market, index) in markets"
      :key="index"
      :class="[
        'live-updates-market-module-link',
        market.change < 0 ? 'red' : 'green',
      ]"
      :href="market.url"
      data-analytics-product-module="markets_banner"
      data-analytics-product-area="index"
    >
      <div class="live-markets-module-wrapper">
        <b
          :class="[
            'live-market-module-link-headline',
            market.change < 0 ? 'red' : 'green',
            'headline-bold',
          ]"
        >
          <div class="arrow-title-wrapper">
            <div class="arrow-indicator"></div>
            <span>{{ market.name }} </span>
          </div>
          <span class="headline-bold percent-indicator js-percent">
            {{ market.change }}%</span
          >
        </b>
      </div>
    </a>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SiteTicker",
  data() {
    return {
      markets: [],
      symbols: ["META", "TSLA", "AAPL"],
      intervalId: null,
      pollingInterval: 60000,
      /*
      markets: [
        {
          name: "Dow Jones",
          change: 0.88,
          url: "https://markets.businessinsider.com/index/dow_jones",
        },
        {
          name: "Nasdaq",
          change: 1.49,
          url: "https://markets.businessinsider.com/index/nasdaq_100",
        },
        {
          name: "S&P 500",
          change: 1.17,
          url: "https://markets.businessinsider.com/index/s&p_500",
        },
        {
          name: "META",
          change: 2.14,
          url: "https://markets.businessinsider.com/stocks/META-stock",
        },
        {
          name: "TSLA",
          change: -2.69,
          url: "https://markets.businessinsider.com/stocks/TSLA-stock",
        },
        {
          name: "AAPL",
          change: 0.66,
          url: "https://markets.businessinsider.com/stocks/AAPL-stock",
        },
      ],
      */
    };
  },
  created() {
    this.fetchMarketData();
    this.intervalId = setInterval(this.fetchMarketData, this.pollingInterval);
  },
  methods: {
    async fetchMarketData() {
      const apiKey = "C8N6JHZ5BSMRECN2"; // Replace with your Alpha Vantage API key
      const baseUrl =
        "https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&interval=5min";

      const promises = this.symbols.map((symbol) =>
        axios.get(`${baseUrl}&symbol=${symbol}&apikey=${apiKey}`)
      );

      try {
        const responses = await Promise.all(promises);

        this.markets = responses.map((response, index) => {
          if (
            response &&
            response.data &&
            response.data["Time Series (5min)"]
          ) {
            const timeSeries = response.data["Time Series (5min)"];
            const latestTime = Object.keys(timeSeries)[0];
            const latestData = timeSeries[latestTime];
            const close = parseFloat(latestData["4. close"]);
            const open = parseFloat(latestData["1. open"]);
            const change = (((close - open) / open) * 100).toFixed(2);

            return {
              name: this.symbols[index],
              change: parseFloat(change),
              url: `https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&symbol=${this.symbols[index]}`, // Placeholder URL
            };
          } else {
            console.error(
              `Error with data for symbol: ${this.symbols[index]}`,
              response
            );
            return {
              name: this.symbols[index],
              change: 0,
              url: `https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&symbol=${this.symbols[index]}`, // Placeholder URL
            };
          }
        });
      } catch (error) {
        console.error("Error fetching market data:", error);
      }
    },
  },
};
</script>

<style>
.markets-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.live-updates-market-module-link {
  text-decoration: none;
  color: inherit;
  margin: 10px;
}
.live-markets-module-wrapper {
  display: flex;
  align-items: center;
}
.live-market-module-link-headline {
  display: flex;
  align-items: center;
}
.green {
  color: var(--green);
}
.red {
  color: var(--red);
}
.headline-bold {
  font-weight: bold;
}
.arrow-title-wrapper {
  display: flex;
  align-items: center;
}
.arrow-indicator {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
.live-market-module-link-headline.red .arrow-indicator {
  top: 10px;
  right: 10px;
  width: 0;
  transform: rotate(45deg);
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid var(--red);
  margin-top: 5px;
}
.live-market-module-link-headline.green .arrow-indicator {
  top: 10px;
  right: 10px;
  width: 0;
  transform: rotate(-45deg);
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid var(--green);
  margin-top: -4px;
}
.percent-indicator {
  margin-left: auto;
  padding-left: 5px;
}
</style>
