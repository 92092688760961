<template>
  <div class="container">
    <section v-if="article" class="inner-page-hero">
      <article>
        <div class="row">
          <div class="col-12 col-sm-12 col-lg-12 col-xl-12">
            <div class="article-category">
              <span class="article-home" @click="goBack()">
                Home <span>></span> </span
              >{{ article.category }}
            </div>
          </div>
          <div class="col-12 col-sm-12 col-lg-12 col-xl-12">
            <div class="article-text">
              <h2>{{ article.title }}</h2>
              <div class="article-author-date">
                <span class="article-author">{{ article.author.name }}</span>
                <span class="article-date">{{ article.date }}</span>
                <span class="article-share" @click="copyLink"> Share </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-lg-12 col-xl-12">
            <div class="image-and-text">
              <img :src="article.image" :alt="article.title" />
            </div>
          </div>
          <div class="col-12 col-sm-12 col-lg-12 col-xl-12">
            <div class="article-text">
              <div class="bullet-points">
                <ul>
                  <li
                    v-for="(bullet, index) in article.article.bullet"
                    :key="index"
                  >
                    {{ bullet }}
                  </li>
                </ul>
              </div>
              <div
                class="text-images-content"
                v-for="(content, index) in article.article.content"
                :key="index"
              >
                <div class="article-text">
                  <p v-if="content">{{ content }}</p>
                  <img
                    v-if="article.article.images[index]"
                    :src="article.article.images[index]"
                    :alt="article.title"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
    <section v-else class="inner-page-hero">
      <!-- Loader -->
      <div class="loader">Loading...</div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// Configure Toastr options if needed
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

export default {
  name: "InnerPage",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      article: null,
    };
  },
  computed: {
    ...mapState(["articles"]),
  },
  watch: {
    articles: {
      handler(newArticles) {
        this.article = newArticles.find((article) => article.name === this.id);
      },
      immediate: true,
    },
  },
  created() {
    this.fetchArticle();
  },
  methods: {
    ...mapActions(["fetchArticles"]),
    fetchArticle() {
      if (!this.articles.length) {
        this.fetchArticles().then(() => {
          this.article = this.articles.find(
            (article) => article.name === this.id
          );
          if (!this.article) {
            console.error(`Article with name ${this.id} not found`);
          }
          this.updateMetaTags();
        });
      } else {
        this.article = this.articles.find(
          (article) => article.name === this.id
        );
        if (!this.article) {
          console.error(`Article with name ${this.id} not found`);
        }
        this.updateMetaTags();
      }
    },
    updateMetaTags() {
      document.title = this.article ? this.article.title : "Loading...";
      const metaTags = document.querySelectorAll(
        'meta[name="date"], meta[name="author"], meta[name="tags"]'
      );
      metaTags.forEach((tag) => {
        if (this.article) {
          if (tag.getAttribute("name") === "date") {
            tag.setAttribute("content", this.article.date);
          } else if (tag.getAttribute("name") === "author") {
            tag.setAttribute("content", this.article.author.name);
          } else if (tag.getAttribute("name") === "tags") {
            tag.setAttribute("content", this.article.tags.join(", "));
          }
        } else {
          // Reset meta tags if article data is not available
          tag.setAttribute("content", "Loading...");
        }
      });
    },
    goBack() {
      this.$router.back();
    },
    copyLink() {
      const link = window.location.href;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          toastr.success("Article link copied");
        })
        .catch((err) => {
          toastr.error("Failed to copy the link");
          console.error("Failed to copy the link: ", err);
        });
    },
  },
};
</script>

<style scoped>
.inner-page-hero .image-and-text img {
  width: 100%;
  padding: 15px 0;
}
.inner-page-hero .article-category {
  padding: 20px 0 10px 0;
}
.inner-page-hero .text-images-content img {
  width: 100%;
  padding: 10px 0;
}
.inner-page-hero .bullet-points ul {
  list-style-type: square;
}
.inner-page-hero .bullet-points ul li {
  font-weight: bold;
  padding: 5px 0;
  font-size: 18px;
}
.inner-page-hero .bullet-points ul li::marker {
  color: var(--link-color);
}
.inner-page-hero .article-share {
  float: right;
  color: var(--secondary-color);
}
.inner-page-hero .article-share:hover {
  cursor: pointer;
}
</style>
