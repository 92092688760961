<template>
  <div>
    <div class="layout-sidemenu" :class="{ open: isSidebarOpen }">
      <SideMenu :articles="articles" @toggle-sidebar="toggleSidebar" />
    </div>
    <div class="layout-main">
      <SiteHeader :articles="articles" @toggle-sidebar="toggleSidebar" />
      <router-view :articles="articles" />
      <SiteFooter :articles="articles" />
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "toastr/build/toastr.min.css";
import "./assets/fonts.css";
import "./assets/styles.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { mapState, mapActions } from "vuex";
import SiteHeader from "./components/layout/SiteHeader.vue";
import SideMenu from "./components/common/SideMenu.vue";
import SiteFooter from "./components/layout/SiteFooter.vue";
import CookieConsent from "@grrr/cookie-consent";

const cookieConsent = CookieConsent({
  cookies: [
    {
      id: "functional",
      label: "Functional",
      description:
        "Essential for the website to function properly. These cookies ensure basic functionalities and security features of the website.",
      required: true,
    },
    {
      id: "analytics",
      label: "Analytics",
      description:
        "Help us understand how our website is being used. These cookies collect information anonymously.",
      checked: false,
    },
    {
      id: "marketing",
      label: "Marketing",
      description:
        "Used to track visitors across websites to deliver relevant ads and marketing campaigns.",
      checked: false,
    },
  ],
  content: {
    message:
      "We use cookies to ensure you get the best experience on our website. By using our site, you consent to the use of cookies.",
    dismiss: "Got it!",
    link: "Learn more",
    href: "/privacy-policy",
  },
  palette: {
    popup: { background: "#000", text: "#fff" },
    button: { background: "#f1d600" },
  },
  theme: "classic",
  type: "opt-in",
});

document.body.insertBefore(
  cookieConsent.getDialog(),
  document.body.firstElementChild
);

export default {
  name: "App",
  components: {
    SiteHeader,
    SideMenu,
    SiteFooter,
  },
  data() {
    return {
      isSidebarOpen: false,
    };
  },
  computed: {
    ...mapState(["articles"]),
  },
  created() {
    this.fetchArticles();
  },
  methods: {
    ...mapActions(["fetchArticles"]),
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
};
</script>

<style scoped>
.layout-sidemenu {
  transition: width 0.3s;
  width: 300px;
  transition: transform 0.3s ease;
  background-color: #fff;
  z-index: 1;
  position: fixed;
  transform: translateX(0);
}
.layout-sidemenu.open {
  width: 0;
  transform: translateX(300px);
  z-index: 102;
  position: fixed;
}
.layout-sidemenu.open .side-menu {
  -webkit-box-shadow: 8px 4px 21px 5px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 8px 4px 21px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 8px 4px 21px 5px rgba(0, 0, 0, 0.5);
}
.layout-main {
  transition: margin-left 0.3s;
  margin-left: 0;
}
</style>
