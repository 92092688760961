<template>
  <nav class="main-menu">
    <ul>
      <!-- Render dynamic menu items based on article categories -->
      <li v-for="(category, index) in uniqueCategories" :key="index">
        <router-link :to="`/category/${category}`">{{ category }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "MainMenu",
  props: {
    articles: {
      type: Array,
      default: () => [], // Default to an empty array if articles prop is not provided
    },
  },
  computed: {
    uniqueCategories() {
      // Check if articles is defined and is an array
      if (Array.isArray(this.articles)) {
        const categories = this.articles.map((article) => article.category);
        return [...new Set(categories)];
      }
      return [];
    },
  },
};
</script>

<style scoped>
.main-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the menu items horizontally */
  padding: 5px 0;
  border-bottom: 1px solid var(--lightgray);
}

.main-menu li {
  margin-right: 20px;
}

.main-menu li:last-child {
  margin-right: 0; /* Remove the margin from the last item */
}

.main-menu a {
  text-decoration: none;
  font-size: 12px;
  color: var(--gray);
}

.main-menu a:hover {
  color: var(--black);
}
</style>
