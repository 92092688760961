import { createStore } from 'vuex';
import axios from 'axios';

const token = process.env.VUE_APP_API_TOKEN;

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  },
});

const store = createStore({
  state() {
    return {
      articles: [],
      // authors: []
    };
  },
  mutations: {
    setArticles(state, articles) {
      state.articles = articles;
    },
  },
  actions: {
    fetchArticles({ commit }) {
      return apiClient.get('/articles')
        .then(response => {
          commit('setArticles', response.data);
        })
        .catch(error => {
          console.error('Error fetching articles:', error);
        });
    },
  },
});

export default store;
