<template>
  <footer class="footer">
    <div class="top-row">
      <div class="social-icons">
        <i class="fa-brands fa-facebook"></i>
        <i class="fa-brands fa-x-twitter"></i>
        <i class="fa-brands fa-instagram"></i>
      </div>
    </div>
    <div class="bottom-row">
      <MainMenu :articles="articles" />

      <div class="middle-row">
        <!-- Copyright text -->
        <p>
          &copy; 2024. DDWebsite. All rights reserved. Registration on or use of
          this site constitutes acceptance of our Terms of Service and Privacy
          Policy.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import MainMenu from "./../common/MainMenu.vue";

export default {
  name: "SiteFooter",
  components: {
    MainMenu,
  },
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.footer {
  border-top: 1px solid var(--lightgray);
}

.top-row {
  display: flex;
  justify-content: center; /* Center the social icons */
  padding: 10px 20px;
}

.social-icons {
  display: flex;
  align-items: center;
}

.social-icons a {
  color: var(--white);
  text-decoration: none;
  margin-right: 10px;
}

.social-icons i {
  font-size: 24px;
  padding: 5px;
}

.social-icons i:hover {
  cursor: pointer;
}

.middle-row {
  text-align: center;
  padding: 10px 20px;
}
</style>
