<template>
  <div class="aside-section">
    <div
      v-for="(article, index) in sortedArticles.slice(0, articlesNumber)"
      :key="index"
    >
      <SiteArticle
        :article="article"
        :layout="layout"
        :showImage="true"
        :showCategory="false"
        :showExcerpt="false"
      />
    </div>
  </div>
</template>

<script>
import SiteArticle from "./../articles/SiteArticle.vue";

export default {
  name: "AsideSection",
  components: {
    SiteArticle,
  },
  props: {
    articles: {
      type: Array,
      required: true,
      default: () => [],
    },
    layout: {
      type: String,
      default: "first-column",
    },
    articlesNumber: {
      type: Number,
      required: true,
      default: 3,
    },
  },
  computed: {
    sortedArticles() {
      return this.articles.slice().sort((a, b) => a.order - b.order);
    },
  },
};
</script>
