<template>
  <div class="container">
    <!-- Loader -->
    <div v-if="loading" class="loader">
      <img src="../../assets/images/loader.svg" alt="loader" />
    </div>
    <!-- Main content -->
    <div v-else>
      <!--TICKER-->
      <div class="row">
        <SiteTicker />
      </div>
      <!--MAIN SECTION-->
      <div class="row">
        <div class="col-12 col-sm-12 col-lg-9 col-xl-9">
          <div class="row">
            <!--SPACER-->
            <span class="spacer"></span>
            <!-- First column -->
            <div class="col-12 col-sm-4 col-lg-4 col-xl-4">
              <div class="card">
                <div class="card-body">
                  <MainSection :articles="mainArticles" layout="first-column" />
                </div>
              </div>
            </div>

            <!-- Second column -->
            <div class="col-12 col-sm-8 col-lg-8 col-xl-8">
              <div class="card">
                <div class="card-body">
                  <MainSection
                    :articles="mainArticles"
                    layout="second-column"
                  />
                </div>
              </div>
            </div>
            <!--SPACER-->
            <span class="spacer"></span>
            <!--SECONDARY SECTION-->
            <!-- First column -->
            <div class="col-12 col-sm-4 col-lg-4 col-xl-4">
              <div class="card">
                <div class="card-body">
                  <SecondarySection
                    :articles="secondaryArticles"
                    layout="first-column"
                  />
                </div>
              </div>
            </div>
            <!-- Second column -->
            <div class="col-12 col-sm-8 col-lg-8 col-xl-8">
              <div class="card">
                <div class="card-body">
                  <SecondarySection
                    :articles="secondaryArticles"
                    layout="second-column"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Third column -->
        <!--TODO: dynamic hide on < 576px -->
        <div class="col-12 col-sm-12 col-lg-3 col-xl-3">
          <div class="card">
            <div class="card-body">
              <MainSection :articles="mainArticles" layout="third-column" />
            </div>
          </div>
          <div class="card">
            <!--SPACER-->
            <span class="spacer"></span>
            <div class="card-body">
              <PopularLinksSection :articles="articles" layout="first-column" />
            </div>
          </div>
        </div>
      </div>

      <!--TOPICS SECTION-->
      <div v-for="category in uniqueCategories" :key="category" class="row">
        <div class="col-12 col-sm-12 col-lg-9 col-xl-9">
          <div class="card">
            <span class="spacer"></span>
            <div class="card-body">
              <TopicsSection
                :articles="articlesByCategory(category)"
                :category="category"
                layout="first-column"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-lg-9 col-xl-9">
          <div class="card">
            <div class="card-body">
              <TopicsSection
                :articles="articlesByCategory(category)"
                :category="category"
                layout="second-column"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainSection from "../common/sections/MainSection.vue";
import SecondarySection from "../common/sections/SecondarySection.vue";
import PopularLinksSection from "../common/sections/PopularLinksSection.vue";
import SiteTicker from "./../common/SiteTicker.vue";
import TopicsSection from "../common/sections/TopicsSection.vue";

export default {
  name: "LandingPage",
  components: {
    SiteTicker,
    MainSection,
    PopularLinksSection,
    SecondarySection,
    TopicsSection,
  },
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    mainArticles() {
      return this.articles.filter((article) => article.section === "main");
    },
    secondaryArticles() {
      return this.articles.filter((article) => article.section === "secondary");
    },
    uniqueCategories() {
      const categories = this.articles.map((article) => article.category);
      return [...new Set(categories)];
    },
  },
  methods: {
    articlesByCategory(category) {
      return this.articles.filter((article) => article.category === category);
    },
  },
  watch: {
    articles: {
      handler(newVal) {
        if (newVal && newVal.length) {
          this.loading = false;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
