<template>
  <div class="popular-links-section">
    <h2 class="popular-header-title">Most popular</h2>
    <ol class="popular-links-ol" type="1">
      <li
        class="popular-links-list"
        v-for="(article, index) in sortedArticles.slice(0, articlesNumber)"
        :key="index"
      >
        <SiteArticle
          :article="article"
          :layout="layout"
          :showImage="false"
          :showCategory="false"
          :showExcerpt="false"
        />
      </li>
    </ol>
  </div>
</template>

<script>
import SiteArticle from "./../articles/SiteArticle.vue";

export default {
  name: "PopularLinksSection",
  components: {
    SiteArticle,
  },
  props: {
    articles: {
      type: Array,
      required: true,
      default: () => [],
    },
    layout: {
      type: String,
      default: "first-column",
    },
    articlesNumber: {
      type: Number,
      required: true,
      default: 5,
    },
  },
  computed: {
    articleClass() {
      return {
        "tout as-main with-description with-image-main": true,
      };
    },
    sortedArticles() {
      return this.articles.slice().sort((a, b) => b.counter - a.counter);
    },
  },
};
</script>

<style>
.most-popular-numbering {
  float: left;
}
.article-category {
  font-size: 1rem;
  color: var(--gray);
}
.article-title {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}
.article-title a {
  text-decoration: none;
  color: var(--text-color);
}
.article-title a:hover {
  text-decoration: underline;
}
.article-excerpt {
  color: var(--excerpt-color);
}
.image-and-text {
  display: flex;
  align-items: center;
}
.image-and-text .article-image {
  margin-right: 10px;
}
.article-image img {
  width: 100%;
  height: auto;
}
.popular-header-title {
  font-size: 18px;
}
</style>
