<template>
  <article :class="articleClass">
    <div class="image-and-text">
      <template v-if="showImage">
        <a class="article-image" :href="article.url">
          <img :src="article.image" :alt="article.title" />
        </a>
      </template>
      <template v-if="showCategory">
        <div class="article-category">
          <router-link :to="`/category/${article.category}`">{{
            article.category
          }}</router-link>
        </div>
      </template>
      <div class="article-text">
        <h2 class="article-title">
          <a
            class="article-link"
            @click.prevent="selectArticle(article.name, article._id)"
            >{{ article.title }}</a
          >
        </h2>
        <p v-if="showExcerpt" class="article-excerpt">{{ articleExcerpt }}</p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "SiteArticle",
  props: {
    article: {
      type: Object,
      required: true,
    },
    layout: {
      type: String,
      default: "third-column",
    },
    showImage: {
      type: Boolean,
      default: false,
    },
    showCategory: {
      type: Boolean,
      default: false,
    },
    showExcerpt: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    articleClass() {
      return {
        "tout as-main with-description with-image-main": true,
        "third-column": this.layout === "second-column",
        // Add other layout classes if needed
      };
    },
    articleExcerpt() {
      if (this.article.excerpt) {
        return this.article.excerpt + " ...";
      } else if (this.article.article.content[0]) {
        return this.article.article.content[0].length > 150
          ? this.article.article.content[0].slice(0, 150) + "..."
          : this.article.article.content[0];
      } else {
        return "";
      }
    },
  },
  methods: {
    async selectArticle(articleName, articleId) {
      try {
        await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/articles/${articleId}/increment-counter`,
          {
            method: "PUT",
          }
        );
      } catch (err) {
        console.error("Failed to increment counter: ", err);
      }
      this.$router.push({ name: "InnerPage", params: { id: articleName } });
    },
  },
};
</script>
