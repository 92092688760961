a
<template>
  <div class="privacy-policy">
    <h1>Privacy Policy</h1>
    <p><strong>Last Updated: 29/05/2024.</strong></p>

    <h2>1. Introduction</h2>
    <p>
      Welcome to DDWebsite. This page informs you of our policies regarding the
      collection, use, and disclosure of personal data when you use our Site and
      the choices you have associated with that data.
    </p>

    <h2>2. Information Collection and Use</h2>
    <p>
      We collect several different types of information for various purposes to
      provide and improve our Site for you.
    </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped>
.privacy-policy {
  margin: 20px;
}

.privacy-policy ul {
  list-style-type: disc;
  margin-left: 20px;
}
</style>
