<template>
  <aside class="side-menu">
    <div class="top-row">
      <!-- Logo -->
      <div class="logo">
        <!-- <SiteLogo /> -->
        <span v-on:click="goToLandingPage()" class="website-logo-title"
          >DDWebsite</span
        >
      </div>
      <!-- Hamburger icon -->
      <button class="hamburger" id="hamburger" v-on:click="toggleMenu">
        <span class="hamburger-box">
          <span
            class="hamburger-inner is-active"
            :class="{ 'is-active': menuOpen }"
          ></span>
        </span>
      </button>
    </div>
    <div class="menu">
      <!-- Menu items -->
      <ul>
        <li
          class="side-menu-category"
          v-for="(category, index) in uniqueCategories"
          :key="index"
        >
          <router-link :to="`/category/${category.name}`">{{
            category.name
          }}</router-link>
          <!--
          <span
            v-if="category.subcategories"
            class="has-subcategories"
            :class="{ minus: category.expanded, plus: !category.expanded }"
          ></span>
          <span @click="toggleSubcategories(category)">{{
            category.name
          }}</span>
          <ul v-if="category.expanded && category.subcategories">
            <li
              class="side-menu-subcategory"
              v-for="(subcategory, subIndex) in category.subcategories"
              :key="subIndex"
            >
              <a href="#">{{ subcategory }}</a>
            </li>
          </ul>
          -->
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
export default {
  name: "SideMenu",
  data() {
    return {
      menuOpen: false,
    };
  },
  props: {
    articles: {
      type: Array,
      default: () => [], // Default to an empty array if articles prop is not provided
    },
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
      this.$emit("toggle-sidebar");
    },
    goToLandingPage() {
      this.$router.push({ name: "LandingPage" });
    },
    toggleSubcategories(category) {
      category.expanded = !category.expanded;
      this.$forceUpdate(); // Force Vue to update the DOM
    },
  },
  computed: {
    uniqueCategories() {
      // Check if articles is defined and is an array
      if (Array.isArray(this.articles)) {
        const categories = [];
        this.articles.forEach((article) => {
          const existingCategory = categories.find(
            (cat) => cat.name === article.category
          );
          if (existingCategory) {
            // Category already exists, add subcategory
            if (!existingCategory.subcategories.includes(article.subcategory)) {
              existingCategory.subcategories.push(article.subcategory);
            }
          } else {
            // New category, create it
            const subcategories =
              article.category === "Tech"
                ? [
                    // "Science",
                    // "AI",
                    // "Sustainability",
                    // "Enterprise",
                    // "Transportation",
                    // "Startups",
                    // "Innovation",
                  ]
                : [article.subcategory];
            categories.push({
              name: article.category,
              subcategories: subcategories,
              expanded: false, // Initially collapsed
            });
          }
        });
        return categories;
      }
      return [];
    },
  },
};
</script>

<style scoped>
.side-menu-category > ul {
  margin: 15px 0;
}

.side-menu-category:hover {
  cursor: pointer;
}

.side-menu-subcategory {
  padding-left: 20px;
}

.side-menu-subcategory a {
  font-weight: bolder;
  font-size: 12px;
}

.side-menu {
  background: var(--white);
  border-right: 1px solid var(--lightgray);
  width: 300px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -300px; /* Initially hidden */
  transition: left 0.3s ease;
  z-index: 101;
}

.side-menu .website-logo-title {
  font-weight: bold;
}

.side-menu .website-logo-title:hover {
  cursor: pointer;
}

.side-menu .hamburger {
  position: absolute;
  right: 10px;
  top: 15px;
}

.side-menu .top-row {
  height: 61px;
}

.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid var(--lightgray);
}

.logo img {
  height: 30px;
}

.close-icon {
  cursor: pointer;
}

.menu {
  padding: 20px;
}

.menu ul {
  list-style-type: none;
  padding: 0;
}

.menu li {
  margin-bottom: 10px;
}

.menu a {
  text-decoration: none;
}

.menu a:hover {
  text-decoration: underline;
}

.has-subcategories {
  margin-right: 5px;
  display: inline-block;
}

.has-subcategories.plus {
  width: 8px;
}

.has-subcategories.minus {
  width: 8px;
}

.plus:before {
  content: "+";
}

.minus:before {
  content: "-";
}
</style>
