<template>
  <div class="topics-section">
    <div v-if="layout === 'first-column'" class="second-column">
      <div class="image-and-text">
        <div v-if="articleWithLowestOrder">
          <SiteArticle
            :article="articleWithLowestOrder"
            :layout="layout"
            :showImage="true"
            :showCategory="true"
            :showExcerpt="false"
          />
        </div>
      </div>
    </div>
    <div
      v-if="layout === 'second-column' && firstColumnArticles.length > 0"
      class="first-column"
    >
      <div class="row">
        <span class="spacer"></span>
        <div class="topics-columns col-12 col-sm-6 first">
          <!-- First column with the article with lowest order -->
          <div v-for="(article, index) in firstColumnArticles" :key="index">
            <SiteArticle
              :article="article"
              :layout="layout"
              :showImage="false"
              :showCategory="false"
              :showExcerpt="true"
            />
          </div>
        </div>
        <div class="topics-columns col-12 col-sm-6 last">
          <div v-for="(article, index) in secondColumnArticles" :key="index">
            <SiteArticle
              :article="article"
              :layout="layout"
              :showImage="false"
              :showCategory="false"
              :showExcerpt="true"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="layout === 'third-column'" class="third-column">
      <AsideSection :articles="[articleWithLowestOrder]" />
    </div>
  </div>
</template>

<script>
import AsideSection from "./AsideSection.vue";
import SiteArticle from "./../articles/SiteArticle.vue";

export default {
  name: "TopicsSection",
  components: {
    AsideSection,
    SiteArticle,
  },
  props: {
    articles: {
      type: Array,
      required: true,
    },
    layout: {
      type: String,
      default: "first-column",
    },
  },
  computed: {
    articleWithLowestOrder() {
      if (!this.articles || this.articles.length === 0) {
        return null;
      }
      return this.articles.reduce((lowest, article) => {
        return article.order < lowest.order ? article : lowest;
      }, this.articles[0]);
    },
    // Compute the first three articles for the first column
    firstColumnArticles() {
      return this.layout === "second-column"
        ? this.articles.slice(1, 4)
        : this.articles.slice(0, 3);
    },
    // Compute the next three articles for the second column
    secondColumnArticles() {
      return this.layout === "second-column"
        ? this.articles.slice(4, 7)
        : this.articles.slice(3, 6);
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
