<template>
  <div class="secondary-section">
    <div v-if="layout === 'first-column'" class="first-column">
      <AsideSection :articles="articles" :articlesNumber="articlesNumber" />
    </div>
    <div v-else-if="layout === 'second-column'" class="second-column">
      <div class="image-and-text sticky">
        <div v-for="(article, index) in stickyArticles" :key="index">
          <SiteArticle
            :article="article"
            :layout="layout"
            :showImage="true"
            :showCategory="false"
            :showExcerpt="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AsideSection from "./AsideSection.vue";
import SiteArticle from "./../articles/SiteArticle.vue";

export default {
  name: "SecondarySection",
  components: {
    AsideSection,
    SiteArticle,
  },
  props: {
    // DUMMY DATA
    articles: {
      type: Array,
      required: true,
    },
    layout: {
      type: String,
      default: "first-column", // Set a default value
    },
    articlesNumber: {
      type: Number,
      required: true,
      default: 2,
    },
  },
  computed: {
    articleExcerpt() {
      if (this.articles) {
        const stickyArticle = this.articles.filter(
          (article) => article.type === "sticky-article-secondary"
        );

        return stickyArticle[0].article.content[0].slice(0, 150) + "...";
      } else {
        return [];
      }
    },
    stickyArticles() {
      // Add a check to ensure that this.articles is defined
      if (this.articles) {
        return this.articles.filter(
          (article) => article.type === "sticky-article-secondary"
        );
      } else {
        return [];
      }
    },
    articleClass() {
      return {
        "tout as-main with-description with-image-main": true,
        "first-column": this.layout === "first-column",
        "second-column": this.layout === "second-column",
      };
    },
  },
};
</script>

<style></style>
