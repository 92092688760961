<template>
  <div class="container">
    <section class="inner-page-hero">
      <div v-if="filteredArticles.length" class="categories-column">
        <div v-for="(article, index) in filteredArticles" :key="index">
          <SiteArticle
            :article="article"
            :layout="layout"
            :showImage="true"
            :showCategory="false"
            :showExcerpt="true"
          />
        </div>
      </div>

      <div v-else>
        <p>No articles found for this category.</p>
      </div>
    </section>
  </div>
</template>

<script>
import SiteArticle from "./../common/articles/SiteArticle.vue";

export default {
  name: "CategoriesPage",
  components: {
    SiteArticle,
  },
  props: {
    articles: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    filteredArticles() {
      return this.articles.filter((article) => article.category === this.id);
    },
  },
};
</script>

<style scoped>
.inner-page-hero .image-and-text img {
  width: 100%;
  padding: 15px 0;
}
.inner-page-hero .article-category {
  padding: 20px 0 10px 0;
}
.inner-page-hero .text-images-content img {
  width: 100%;
  padding: 10px 0;
}
.inner-page-hero .bullet-points ul {
  list-style-type: square;
}
.inner-page-hero .bullet-points ul li {
  font-weight: bold;
  padding: 5px 0;
  font-size: 18px;
}
.inner-page-hero .bullet-points ul li::marker {
  color: var(--link-color);
}
</style>
