<template>
  <div class="main-section">
    <div v-if="layout === 'first-column'" class="first-column">
      <div
        v-for="(article, index) in sortedArticles.slice(0, articlesNumber)"
        :key="index"
      >
        <SiteArticle
          :article="article"
          :layout="layout"
          :showImage="false"
          :showCategory="true"
        />
      </div>
    </div>
    <div v-else-if="layout === 'second-column'" class="second-column">
      <div class="image-and-text sticky">
        <div v-for="(article, index) in stickyArticles" :key="index">
          <SiteArticle
            :article="article"
            :layout="layout"
            :showImage="true"
            :showCategory="false"
            :showExcerpt="true"
          />
        </div>
      </div>
    </div>

    <div v-else-if="layout === 'third-column'" class="third-column">
      <AsideSection :articles="articles" :articlesNumber="3" />
    </div>
  </div>
</template>

<script>
import AsideSection from "./AsideSection.vue";
import SiteArticle from "./../articles/SiteArticle.vue";

export default {
  name: "MainSection",
  components: {
    AsideSection,
    SiteArticle,
  },
  props: {
    articles: {
      type: Array,
      required: true,
    },
    layout: {
      type: String,
      default: "first-column", // Set a default value
    },
    articlesNumber: {
      type: Number,
      required: true,
      default: 5,
    },
  },
  computed: {
    stickyArticles() {
      // Add a check to ensure that this.articles is defined
      if (this.articles) {
        return this.articles.filter(
          (article) => article.type === "sticky-article-main"
        );
      } else {
        return [];
      }
    },
    articleClass() {
      return {
        "tout as-main with-description with-image-main": true,
        "first-column": this.layout === "first-column",
        "second-column": this.layout === "second-column",
        // Add other layout classes if needed
      };
    },
    sortedArticles() {
      return this.articles.slice().sort((a, b) => a.order - b.order);
    },
  },
};
</script>

<style>
/* Your styles remain unchanged */
</style>
