// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '../components/pages/LandingPage.vue';
import InnerPage from '../components/pages/InnerPage.vue';
import CategoriesPage from '../components/pages/CategoriesPage.vue';
import ErrorPage from '../components/pages/ErrorPage.vue';
import PrivacyPolicy from '../components/pages/PrivacyPolicy.vue';

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/article/:id',
    name: 'InnerPage',
    component: InnerPage,
    props: true
  },
  {
    path: '/category/:id',
    name: 'CategoriesPage',
    component: CategoriesPage,
    props: true
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    props: true
  },
  {
    path: '/:pathMatch(.*)*', // Catch all undefined routes
    name: 'ErrorPage',
    component: ErrorPage
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.afterEach((to) => {
  document.title = to.meta.title || 'DDWebsite';
  const metaTags = document.querySelectorAll('meta[name="author"], meta[name="date"], meta[name="tags"]');
  metaTags.forEach(tag => {
    if (to.meta[tag.getAttribute('name')]) {
      if (tag.getAttribute('name') === 'tags') {
        tag.setAttribute('content', to.meta.tags.join(', '));
      } else {
        tag.setAttribute('content', to.meta[tag.getAttribute('name')]);
      }
    }
  });
});

export default router;
