<template>
  <header class="header">
    <div class="top-row" :class="{ sticky: isSticky }">
      <div class="left-icons">
        <!-- Hamburger icon -->
        <button class="hamburger" id="hamburger" v-on:click="toggleMenu">
          <span class="hamburger-box">
            <!-- <span
              class="hamburger-inner"
              :class="{ 'is-active': menuOpen }"
            ></span> -->
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
      <div class="logo">
        <!-- <SiteLogo /> -->
        <span v-on:click="goToLandingPage()" class="website-logo-title"
          >DDWebsite</span
        >
      </div>
      <div class="right-icons">
        <!-- User icon -->
        <!-- <i class="fa fa-user user-icon"></i> -->
      </div>
    </div>
    <div class="bottom-row">
      <MainMenu :articles="articles" />
    </div>
  </header>
</template>

<script>
import MainMenu from "./../common/MainMenu.vue";
// import SiteLogo from "./../common/SiteLogo.vue";

export default {
  name: "SiteHeader",
  components: {
    MainMenu,
    // SiteLogo,
  },
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      menuOpen: false,
      isSticky: false,
      headerHeight: 120,
    };
  },
  mounted() {
    // Calculate the height of the header
    this.headerHeight = this.$el.offsetHeight;

    // Add event listener to window scroll
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // Remove event listener when component is destroyed
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    goToLandingPage() {
      this.$router.push({ name: "LandingPage" });
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
      this.$emit("toggle-sidebar");
    },
    handleScroll() {
      this.isSticky = window.pageYOffset >= this.headerHeight;
    },
  },
};
</script>

<style scoped>
.header .website-logo-title {
  font-size: 24px;
  border: 2px solid black;
  padding: 0 10px;
  font-weight: bolder;
}

.header .website-logo-title:hover {
  cursor: pointer;
}

.header .top-row {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid var(--lightgray);
}

.left-icons {
  display: flex;
  align-items: center;
}

.right-icons {
  display: flex;
  align-items: center;
}

.user-icon {
  margin-left: 10px;
}

.logo img {
  height: 40px;
  margin-right: 10px;
}

.ticker {
  background-color: var(--ticker-color);
  padding: 10px 20px;
}

.ticker ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.ticker li {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
}

.fa-arrow-up {
  color: var(--green);
}

.fa-arrow-down {
  color: var(--red);
}

.sticky {
  position: fixed;
  top: -61px;
  z-index: 100;
  width: 100%;
  background-color: var(--white);
  transform: translateY(100%);
  transition: transform 0.3s ease;
}
</style>
